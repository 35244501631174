import React, { useState } from "react";
import "./Nav.css";
import { useNavigate } from "react-router-dom";

const menu: string = require("../../Icons/Menu.svg").default;
const close: string = require("../../Icons/Close.svg").default;
const links: string[] = ["Code", "Design", "Photo", "About"];

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleClick = (e: React.SyntheticEvent) => {
    const id: string = e.currentTarget.id;
    switch (id) {
      case "nav-home": {
        navigate("/");
        break;
      }
      case "nav-code": {
        navigate("/code");
        break;
      }
      case "nav-design": {
        navigate("/design");
        break;
      }
      case "nav-photo": {
        navigate("/photo");
        break;
      }
      case "nav-about": {
        navigate("/about");
        break;
      }
      default: {
        break;
      }
    }
    setShowMenu(false);
  };

  const handleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <div className="content nav" id="page-top">
      {showMenu ? (
        <div className="nav__links-mobile">
          <div className="nav__links-close clickable" onClick={handleMenu}>
            <img src={close} alt="Close Menu" />
          </div>
          {links.map((link, index) => (
            <h1
              className="clickable"
              id={`nav-${link.toLowerCase()}`}
              key={index}
              onClick={handleClick}
            >
              {link}
            </h1>
          ))}
        </div>
      ) : (
        <></>
      )}
      <div className="nav__items horz-elements">
        <h4
          className="hover__link clickable"
          id="nav-home"
          onClick={handleClick}
        >
          markcwy
        </h4>
        <div className="nav__links">
          {links.map((link, index) => (
            <h4
              className="hover__link clickable"
              id={`nav-${link.toLowerCase()}`}
              key={index}
              onClick={handleClick}
            >
              {link}
            </h4>
          ))}
        </div>
        <div className="nav__links-hamburger clickable" onClick={handleMenu}>
          <img src={menu} alt="Hamburger Menu" />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
