import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import MainOutlet from "./Outlets/MainOutlet";

// —————————— Pages —————————— //

import HomePage from "./Pages/HomePage/HomePage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import PortfolioPage from "./Pages/PortfolioPage/PortfolioPage";
import ProjectPage from "./Pages/ProjectPage/ProjectPage";
import FilteredPage from "./Pages/FilteredPage/FilteredPage";

// ———————————————————— //

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route element={<MainOutlet />}>
          <Route index element={<HomePage />} />

          <Route path="/code">
            <Route index element={<PortfolioPage />} />
            <Route path=":projectId" element={<ProjectPage />} />
          </Route>

          <Route path="/design">
            <Route index element={<PortfolioPage />} />
            <Route path=":projectId" element={<ProjectPage />} />
          </Route>

          <Route path="/photo">
            <Route index element={<PortfolioPage />} />
            <Route path=":projectId" element={<ProjectPage />} />
          </Route>

          <Route path="/search" element={<FilteredPage />} />

          <Route path="/about" element={<AboutPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
