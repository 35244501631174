import { ContactInfo, ExperienceInfo, PressInfo } from "../Types/CustomTypes";

const pressData: PressInfo[] = [
  {
    name: "Making Meaning",
    client: "Friendzone",
    link: "https://www.makingmeaning.net/post/mark-chan-navigating-the-friendzone",
  },
  {
    name: "Fast Company",
    client: "Sanzo",
    link: "https://www.fastcompany.com/90671922/sanzos-asian-inspired-sparkling-water-is-better-than-seltzer",
  },
  {
    name: "BevNET",
    client: "Sanzo",
    link: "https://www.bevnet.com/news/2021/sanzo-partners-with-marvel-for-lto-shang-chi-cans/",
  },
  {
    name: "Brand New",
    client: "Museum of Ice Cream",
    link: "https://www.underconsideration.com/brandnew/archives/new_logo_and_identity_for_museum_of_ice_cream_by_the_working_assembly.php",
  },
  {
    name: "Creative Boom",
    client: "Sanzo",
    link: "https://www.creativeboom.com/inspiration/the-working-assembly-rebrands-the-museum-of-ice-cream/",
  },
  {
    name: "The Dieline",
    client: "Sanzo",
    link: "https://thedieline.com/blog/2019/9/27/sanzo-brings-asian-fruit-flavors-to-sparkling-water-market",
  },
];

const experienceData: ExperienceInfo[] = [
  {
    name: "Software Engineer",
    company: "Saltmine",
    location: "Singapore",
    duration: "11/2023 — Present",
  },
  {
    name: "Freelance Designer",
    company: "Self-Employed",
    location: "Singapore",
    duration: "07/2020 — 10/2023",
  },
  {
    name: "Photography Instructor",
    company: "Dunman High School",
    location: "Singapore",
    duration: "11/2020 — 10/2023",
  },
  {
    name: "Design & Art Direction",
    company: "Friendzone",
    location: "Singapore",
    duration: "10/2021 — 03/2023",
  },
  {
    name: "Freelance Designer",
    company: "Bravo",
    location: "Singapore",
    duration: "03/2021 — 09/2021",
  },
  {
    name: "Mid-Level Designer",
    company: "The Working Assembly",
    location: "New York City, USA",
    duration: "06/2018 — 07/2020",
  },
];

const contactData: ContactInfo[] = [
  { title: "work@markcwy.com", link: "mailto:work@markcwy.com" },
  { title: "Instagram", link: "https://instagram.com/markcwy/" },
  { title: "Github", link: "https://github.com/markcwy-ra/" },
  { title: "LinkedIn", link: "https://www.linkedin.com/in/markcwy/" },
  { title: "Are.na", link: "https://www.are.na/mark-chan" },
  { title: "Behance", link: "https://www.behance.net/markcwy" },
];

export { pressData, experienceData, contactData };
