import React from "react";
import "./FilteredPage.css";
import { projectDataSummary } from "../../Data/ProjectData";
import Tag from "../../Pieces/Tag/Tag";
import { useSearchParams } from "react-router-dom";
import ProjectGallery from "../../Parts/ProjectGallery/ProjectGallery";
import { TagType } from "../../Types/CustomTypes";
import Fade from "../../Motion/Fade";

const FilteredPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const tag: TagType = searchParams.get("tag") as TagType;

  if (tag) {
    return (
      <Fade className="content filtered">
        <div className="filtered__header">
          <h4>Showing Projects Tagged</h4>
          <Tag name={tag} size="large" />
        </div>
        <ProjectGallery
          projects={projectDataSummary.filter((project) =>
            project.tags.includes(tag)
          )}
        />
      </Fade>
    );
  } else {
    return <h1>Loading</h1>;
  }
};

export default FilteredPage;
