import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

const logo: string = require("../../Icons/Logo.svg").default;
const name: string = require("../../Icons/Name.svg").default;

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  const handleClick = (e: React.SyntheticEvent) => {
    const id: string = e.currentTarget.id;
    switch (id) {
      case "footer-home": {
        navigate("/");
        break;
      }
      case "footer-code": {
        navigate("/code");
        break;
      }
      case "footer-design": {
        navigate("/design");
        break;
      }
      case "footer-photo": {
        navigate("/photo");
        break;
      }
      case "footer-about": {
        navigate("/about");
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className="footer">
      <div className="footer__main content">
        <img src={logo} alt="markcwy Logo" />
        <div className="footer__links">
          <div className="footer__links-stack">
            <h4>Site Map</h4>
            <h3
              id="footer-about"
              className="hover__link clickable"
              onClick={handleClick}
            >
              About
            </h3>
            <h3
              id="footer-code"
              className="hover__link clickable"
              onClick={handleClick}
            >
              Code
            </h3>
            <h3
              id="footer-design"
              className="hover__link clickable"
              onClick={handleClick}
            >
              Design
            </h3>
            <h3
              id="footer-photo"
              className="hover__link clickable"
              onClick={handleClick}
            >
              Photo
            </h3>
          </div>
          <div className="footer__links-stack">
            <h4>Links</h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://instagram.com/markcwy/"
            >
              <h3>Instagram</h3>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/markcwy-ra/"
            >
              <h3>Github</h3>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/markcwy/"
            >
              <h3>LinkedIn</h3>
            </a>
          </div>
        </div>
      </div>
      <img className="footer__name" src={name} alt="markcwy Logo" />
      <div className="horz-elements content">
        <h5>Code + Design + Photo</h5>
        <h5>©{year} Mark Chan</h5>
      </div>
    </div>
  );
};

export default Footer;
