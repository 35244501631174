import React from "react";
import "./AboutPage.css";
import { allTags } from "../../Data/Tags";
import Tag from "../../Pieces/Tag/Tag";
import { contactData, experienceData, pressData } from "../../Data/AboutData";
import Fade from "../../Motion/Fade";

const AboutPage: React.FC = () => {
  return (
    <Fade className="content about">
      <div className="about__header">
        <h4>About Me</h4>
        <h2>
          I love developing technical & creative solutions for real world
          problems
        </h2>
      </div>
      <div className="about__content content-offset">
        <div className="about__intro horz-elements">
          <div className="about__intro-para">
            <p>
              Growing up in Singapore, I pursued my passion for art and design
              in the US, graduating with a BFA in Graphic Design before moving
              to New York City to further hone my creative skills working with
              both startups and large corporations.
            </p>
            <p>
              While working on creative solutions, I fell in love with code and
              logic through working with developers and through creative coding,
              eventually deciding to pursue software engineering full time.
            </p>
            <p>
              Brands that I've worked with include Sanzo, Museum of Ice Cream,
              Evian, Spotify and National Youth Council.
            </p>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/profile%2FMARK_2024.JPG?alt=media&token=075397e0-a0f8-4e86-a9ae-baff3a12207d"
            alt="profile"
          />
        </div>
        <div className="about__stats_services">
          <h4>Services</h4>
          <div className="about__stats_services-tags">
            {allTags.map((tag, index) => (
              <Tag name={tag} key={index} size="large" />
            ))}
          </div>
        </div>
        <div className="about__stats">
          <div className="about__stats_column">
            <h4>Experience</h4>
            {experienceData.map((data, index) => (
              <div className="about__stats_subsection" key={index}>
                <h6>{data.name}</h6>
                <h4>{data.company}</h4>
                <div className="about__stats_experience">
                  <h4>{data.location}</h4>
                  <h4>{data.duration}</h4>
                </div>
              </div>
            ))}
          </div>
          <div className="about__stats_column">
            <h4>Press</h4>
            {pressData.map((data, index) => (
              <a
                className="about__stats_subsection"
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                href={data.link}
              >
                <h6>{data.name}</h6>
                <h4>{data.client}</h4>
              </a>
            ))}
          </div>
          <div className="about__stats_column">
            <h4>Contact</h4>
            {contactData.map((data, index) => (
              <a
                className="about__stats_subsection"
                target="_blank"
                rel="noopener noreferrer"
                href={data.link}
                key={index}
              >
                <h6>{data.title}</h6>
              </a>
            ))}
            <h4 className="resume_line">Resume available on request</h4>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default AboutPage;
