import { AllProjects, ProjectTileType } from "../Types/CustomTypes";

const projectData: AllProjects = {
  "makan-where": {
    client: "Makan Where",
    title: "Food Recommendation App",
    headline: "An app for the foodie in everyone",
    tags: ["web design", "frontend", "react", "firebase"],
    type: ["code", "design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmakan-where%2Fmakan-where_cover.webp?alt=media&token=ca8e5166-c465-4485-aec1-cf45638b51dc",
    intro:
      "Makan Where is a food recommendation app that allows users to save and share reviews, food playlists and recommendations with others.",
    link: [
      {
        link: "https://github.com/markcwy-ra/makan-where-frontend",
        label: "Frontend Repo",
      },
      {
        link: "https://github.com/markcwy-ra/makan-where-backend",
        label: "Backend Repo",
      },
    ],
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmakan-where%2Fmakan-where_01.webp?alt=media&token=0c3be646-2199-46e6-be56-399bfe63682f",
        width: "full",
      },
      {
        type: "text",
        content:
          "A React & Firebase frontend handles user content creation, as well as communication with backend RESTful APIs.",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmakan-where%2Fmakan-where_cover.webp?alt=media&token=ca8e5166-c465-4485-aec1-cf45638b51dc",
        width: "half",
      },

      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmakan-where%2Fmakan-where_04.webp?alt=media&token=4e978efc-0257-4871-9a83-c354c36b0af9",
        width: "half",
      },
      {
        type: "text",
        content:
          "Custom JWT access & session-based tokens, as well as a focus on clean coding practices.",
        width: "half",
      },
      {
        type: "text",
        content:
          "User-focused design and interactions to increase usability & intuitiveness.",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmakan-where%2Fmakan-where_05.webp?alt=media&token=1141f8ec-959f-4ab0-a200-c250c5ce6ec4",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmakan-where%2Fmakan-where_03.webp?alt=media&token=32ab1ec2-7435-45d7-b28c-686bf790b175",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Pretty Kaur",
        role: "Backend Developer",
      },
    ],
  },
  pals: {
    client: "P.A.L.S.",
    title: "Participant Management System",
    headline: "An all-in-one portal to manage, track and group event attendees",
    intro:
      "This client-specific app was created for Friendzone Singapore, a community-based organisation that runs events to foster communities in neighbourhoods, schools and organisations. We helped create an app that streamlined the process of participant management.",
    tags: [
      "web design",
      "frontend",
      "react",
      "backend",
      "sequelize",
      "express.js",
    ],
    type: ["code", "design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fpals%2Fpals_cover.webp?alt=media&token=b6c7f558-b4d2-4b17-b8c9-4ff9b5076a0a",
    link: [
      {
        link: "https://github.com/hengmhs/friendzone-frontend",
        label: "Frontend Repo",
      },
      {
        link: "https://github.com/hengmhs/friendzone-backend",
        label: "Backend Repo",
      },
    ],
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fpals%2Fpals_splash.webp?alt=media&token=878cdf58-bd57-41bc-bdb3-dd76e4f5ed38",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Sean Heng",
        role: "Fullstack Developer",
      },
    ],
  },
  "sanzo-updates": {
    client: "Sanzo",
    title: "Updated Can Designs",
    headline: "An updated look with a slice of freshness",
    intro:
      "To coincide with the release of the new Pomelo sparkling water, I worked with Sanzo to update the illustrations for the various fruits on their cans.",
    tags: ["packaging", "illustration"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-updates%2Fsanzo_updates-cover.webp?alt=media&token=d21783d3-0560-46b4-8496-2c40a1dad30d",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-updates%2Fsanzo-updates_all-cans.webp?alt=media&token=61eecc5f-ebb2-4517-927f-076957edb3bb",
        width: "full",
      },
      {
        type: "text",
        content: "Introducing the latest addition to the Sanzo family: Pomelo.",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-updates%2Fsanzo-updates_pomelo-table.webp?alt=media&token=267d68b5-fb61-4135-9181-22862e4843a8",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-updates%2Fsanzo-updates_cooler.webp?alt=media&token=99fcad2a-939b-46dd-be1a-6feced230bed",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-updates%2Fsanzo-updates_multipacks.webp?alt=media&token=780c1852-c2e5-43d2-a581-75297b835d67",
        width: "half",
      },
      {
        type: "text",
        content:
          "I also designed multipacks, which are now available on shelves!",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-updates%2Fsanzo_updates_multipack-lychee.webp?alt=media&token=1a17e647-5ea5-47b9-9c12-65bd3ab0efb5",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Garnish Studios",
        role: "Documentation",
        link: "https://www.garnishstudios.com/",
      },
    ],
  },
  "sanzo-linsanity": {
    client: "Sanzo",
    title: "Linsanity Collaboration",
    headline: "A legendary release",
    intro:
      "To celebrate the 10-year anniversary of Linsanity and the release of HBO Max's 38 At The Garden, I helped Sanzo design a limited edition can collaboration featuring Jeremy Lin and Sanzo's first-ever limited edition flavour, Asian Pear.",
    tags: ["packaging"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_on-ball.webp?alt=media&token=7b6a99a2-f197-41ad-b69a-eecdeae59971",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_court-sit.jpg?alt=media&token=2e3174ca-be10-40f2-8ccd-4924916be71f",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_blue-bg.webp?alt=media&token=67177413-da76-409e-826c-70e47a274d29",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_cans-top.jpg?alt=media&token=bf92ebc5-f9c5-41e6-97cc-1d1a6625b70d",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_shadow.jpg?alt=media&token=8455e87a-bce4-4db1-82ab-5eb0b87337d6",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_court-foot.jpg?alt=media&token=9508491f-8dae-4143-a54c-3c0fd386ff6f",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_hand.jpg?alt=media&token=da16f185-9754-4ba0-bae4-a4d6b27cce27",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-linsanity%2Fsanzo-linsanity_desk.jpg?alt=media&token=598aaea3-4218-4a06-86f5-e226b9a180a7",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Garnish Studios",
        role: "Documentation",
        link: "https://www.garnishstudios.com/",
      },
    ],
  },
  "36days-22": {
    client: "Personal Project",
    title: "36 Days of Type: 2022",
    headline: "36 Days of Household Objects",
    intro:
      "For the 2022 edition of 36 Days of Type, I explored how household objects could be used to form each letter.",
    tags: ["illustration"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2F36days-22%2F36days-22_cover.jpg?alt=media&token=a608af59-534e-4d51-8d48-e1949372ffb5",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2F36days-22%2F36days-22_all.jpg?alt=media&token=51108bba-68cb-42ce-9dad-bb5661aee09e",
        width: "half",
      },
    ],
  },
  "sanzo-marvel": {
    client: "Sanzo",
    title: "Marvel Collaboration",
    headline:
      "Teaming up to celebrate a historic moment for the Asian American Community",
    intro:
      "To celebrate the launch of Marvel Studios' Shang-Chi and the Legend of the Ten Rings, Sanzo collaborated with Marvel to create a series of limited edition cans featuring the main characters of the film.",
    tags: ["packaging"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2FSANZO_Marvel.jpg?alt=media&token=8acbf7f3-5bbe-4376-9836-b3483b779226",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2Fsanzo-marvel_01.webp?alt=media&token=d9996c8a-6431-4c2b-aecd-9b981d7d5690",
        width: "full",
      },
      {
        type: "text",
        content:
          "The cans made their debut on the red carpet at the world premiere at TCL Chinese Theatre in Los Angeles, California.",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2Fsanzo-marvel_02.webp?alt=media&token=0cff4cec-0846-4a5b-8a19-81d746e5a0b3",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2Fsanzo-marvel_04.webp?alt=media&token=1cf5e5c6-1182-4036-9b6f-6b695db2b64d",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2Fsanzo-marvel_03.webp?alt=media&token=da9f35d2-a63d-4fe4-8a69-81e5a0325989",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2Fsanzo-marvel_05.webp?alt=media&token=7737c96f-0526-4526-8cfd-deb3ddf78ebe",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo-marvel%2Fsanzo-marvel_06.webp?alt=media&token=cdfc55b0-d3c3-4a75-a223-615d14af12e4",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Garnish Studios",
        role: "Documentation",
        link: "https://www.garnishstudios.com/",
      },
    ],
  },
  htht: {
    client: "National Youth Council",
    title: "Branding for htht.co",
    headline:
      "A space for heart to heart conversations about all things relationships",
    intro:
      "HTHT Collective is a space for intimate, honest and serious talks on all things relationships. Hosting events and talks, they aim to create an environment that facilitates questions and conversations about topics that are weighing on the mind but never expressed. I was tasked with create a brand identity that was approachable yet trendy to appeal to a modern crowd of students and young professionals.",
    tags: ["brand identity", "illustration", "copywriting"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_cover.jpg?alt=media&token=f0d97a72-5121-4c0c-b340-668c7c18c7c9",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_logo.jpg?alt=media&token=e5afde0c-6383-4b54-9ad4-477f361ca064",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_illo.jpg?alt=media&token=35d10d49-eb7d-4aab-970a-e3d9ea8238ec",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_social.jpg?alt=media&token=47f24327-f749-402a-ad35-50662e40132c",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_story.jpg?alt=media&token=e42aba38-0c31-48ab-b0c9-91fa312d996b",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_hey.jpg?alt=media&token=f40c2321-2a43-4f82-9da2-3ca6d7ab9f7b",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_phone-case.jpg?alt=media&token=0882d8d6-0ae6-4359-baf9-ba8d3acbbb10",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_event-singleness.jpg?alt=media&token=574147b4-deaf-43d0-8e98-81f498b6eb17",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fhtht%2Fhtht_event-romance.jpg?alt=media&token=1a24d40c-767f-431d-80fb-a4e5f5e45c31",
        width: "half",
      },
    ],
  },
  "sg-math": {
    client: "Wenxi Lee",
    title: "Secrets To Singapore Math",
    headline: "Sharing the secrets to Singapore's world-class Math curriculum",
    intro:
      "Written by Wenxi Lee, a Singaporean doctoral student in mathematics education at the University of Illinois at Chicago, this book dives deep into Singapore's math curriculum, guiding you through the history and context around it, as well as providing advice and tips on implementing the curriculum effectively.",
    tags: ["editorial", "illustration"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsg-math%2Fsg-math_cover.jpg?alt=media&token=7ed2daaf-bfe3-4a59-be33-da792ed31f65",
    link: [
      {
        link: "https://www.singaporemath.com/products/the-secrets-to-singapores-world-class-math-curriculum",
        label: "Buy a copy",
      },
    ],
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsg-math%2Fsg-math_stack.jpg?alt=media&token=337833de-cfbf-424b-a58c-2742e3edabfa",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsg-math%2Fsg-math_back-front.jpg?alt=media&token=b24abe58-8213-4c4e-b554-0c9863f07c69",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsg-math%2Fsg-math_illo.jpg?alt=media&token=0c73823c-3be9-4adf-8592-1334894776af",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsg-math%2Fsg-math_spreads.jpg?alt=media&token=3dd78f66-b0fd-4d27-8e9c-b0a6c2ef1fff",
        width: "full",
      },
    ],
  },
  moic: {
    client: "Figure8",
    title: "Museum of Ice Cream",
    headline: "Adding a little flavour to the Museum of Ice Cream",
    intro:
      "Figure8 approached The Working Assembly to update the brand of Museum of Ice Cream. As part of the design team, I helped with initial logo and illustration explorations, eventually designing the final logo.",
    tags: ["brand identity", "documentation"],
    type: ["design", "photo"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_cover.jpg?alt=media&token=bd782edd-853e-4666-8417-777a03e116e8",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_sprinkle-pool.jpg?alt=media&token=22861224-ffd7-4dd1-8e5a-a8a74cd3894f",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_circle-sign.jpg?alt=media&token=b4b54eaa-ab8f-40bf-be80-b2283d350450",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_facade.JPG?alt=media&token=43b25595-5918-4b7c-b5b5-2b24f16f5edb",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_subway.jpg?alt=media&token=4b329eec-7ea1-471b-ae63-67465f63ee4b",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_fridge.jpg?alt=media&token=76b5323e-13aa-4597-9bbe-a8c79774531c",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_newspaper.jpg?alt=media&token=53991869-7304-453a-a14d-172146944129",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_corridor.jpg?alt=media&token=8e1b0090-3495-48e3-b540-9bbb7a575fae",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fmoic%2Fmoic_bottles.jpg?alt=media&token=0efaa398-255c-4e25-9381-3ee4b164623f",
        width: "half",
      },
    ],
    credits: [
      {
        name: "Jolene Delisle",
        role: "Creative Director",
      },
      {
        name: "Christine Brown",
        role: "Senior Designer",
        link: "http://christine-brown.com/",
      },
      {
        name: "Adrienne Hugh",
        role: "Illustrator",
        link: "http://www.adriennehugh.com/",
      },
    ],
  },
  "levels-health": {
    client: "Levels Health",
    title: "Brand Identity",
    headline: "Your personal metabolic health dashboard",
    intro:
      "Levels is a new program that monitors your metabolic health, giving you personalised insights into how different foods affect your body and your performance. The Working Assembly was hired to create their overall brand identity, website, packaging and UI for their mobile app.",
    tags: ["brand identity", "web design", "app design", "packaging"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_app-diagonal.jpg?alt=media&token=108af671-f291-4ea0-822f-c559d388484b",
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_website.jpg?alt=media&token=2a7aa1d1-808b-4f01-bf96-84565b9f882e",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_app-flat.jpg?alt=media&token=a1fc3024-7f38-4516-a767-1e82bf3c9581",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_man-patch.jpg?alt=media&token=a898f6ff-4cf2-4683-978b-87d9d2b14454",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_packaging.jpg?alt=media&token=45c829dd-e78d-4d22-8b2d-90a2fb8f33c0",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_billboard.jpg?alt=media&token=1cd32460-b89e-40d5-87c9-d2053109ada8",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_namecards.jpg?alt=media&token=948e7fde-74ba-4ec1-bb3c-445633b0f67b",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_tote.jpg?alt=media&token=7376b0b2-334b-494d-9ee3-d3efc28689a1",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Flevels-health%2Flevels-health_social.jpg?alt=media&token=c711a2cf-f582-4ea3-8710-fe03ccbaeb58",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Lawrence O'Toole",
        role: "Design Director",
      },
      {
        name: "Sarah Sprinkle",
        role: "UI/UX Designer",
      },
    ],
  },
  "justice-hq": {
    client: "Justice HQ",
    title: "Brand Identity",
    headline: "Making space for justice",
    intro:
      "Justice HQ is an exclusive membership for plaintiff attorneys, offering the ideal alternative to the traditional law firm setting. Beyond offering premier spaces, Justice HQ boasts a unique tech-based platform allowing their community to collaborate with peers and scale their firms on their own terms.",
    tags: ["brand identity"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_cover.webp?alt=media&token=a931ca56-e148-4cec-94fa-bd2a330efbc6",
    link: [
      {
        link: "https://justicehq.com/",
        label: "justicehq.com",
      },
    ],
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_large-signange.webp?alt=media&token=a84db7e3-babf-44d3-bdd2-0590923597f7",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_collateral.webp?alt=media&token=3355dd77-c2e7-4cfb-9935-23523e6a36d4",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_interior-01.webp?alt=media&token=cc48dfad-4425-4c07-9977-cf36feb64d4d",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_cover.webp?alt=media&token=a931ca56-e148-4cec-94fa-bd2a330efbc6",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_invite.webp?alt=media&token=d26b5589-511c-4413-b8b3-5f67356cd62b",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_folder.webp?alt=media&token=96604dc6-5e87-4d79-a719-ffc8ada9ccee",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_covid-sign.webp?alt=media&token=a9381870-3591-4b48-b30d-2352c4884ecc",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_interior-02.webp?alt=media&token=27498042-e43b-4337-9db3-8816d1383299",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fjustice-hq%2Fjustice-hq_interior-wide.webp?alt=media&token=5ad2756a-feef-4646-a630-a0e916662d78",
        width: "full",
      },
    ],
    credits: [
      {
        name: "Lawrence O'Toole",
        role: "Design Director",
      },
      {
        name: "Kate Johnston",
        role: "Designer",
        link: "https://katejohnston.design/",
      },
    ],
  },
  sanzo: {
    client: "Sanzo",
    title: "Brand & Packaging",
    headline: "Traditional Asian flavours with a modern twist",
    intro:
      "The Working Assembly worked closely with founder Sandro Roco in creating a brand identity that would both capture the spirit of the product, and have the ability to stand out on a shelf among competitors. As the brand developed, Sandro approached me to continue working on their brand, expanding upon the established system to create more packaging materials and flavour designs.",
    tags: ["brand identity", "packaging"],
    type: ["design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_cover.webp?alt=media&token=91d195c8-369e-4161-a873-182536de9724",
    link: [
      {
        link: "https://drinksanzo.com/",
        label: "drinksanzo.com",
      },
    ],
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_logo.webp?alt=media&token=4f8a044f-7bdb-48d2-865c-48f797c88670",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_cans-hand-wide.webp?alt=media&token=2368725d-df60-4549-ae80-e1965ce735e1",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_cans-jacket.webp?alt=media&token=99f66a12-35aa-4da5-8192-1d449cd2373f",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_sticker.webp?alt=media&token=b8d6b7fb-b94e-488c-88f1-e26fb6c869e3",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_set-lychee.webp?alt=media&token=17d471b2-8372-4ea3-a3cb-dade5097fa57",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_cover.webp?alt=media&token=91d195c8-369e-4161-a873-182536de9724",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_cans-hand.webp?alt=media&token=b5cde7a2-633b-435d-bc60-8bd41fa3d0dd",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_set-yuzu.webp?alt=media&token=2c30718b-e707-4e85-9f75-914c1289e4e6",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_cans-top.webp?alt=media&token=a5e5385b-0751-43ec-ae34-9f17ca2647d4",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_graphics-lychee.webp?alt=media&token=3e4b595e-4ef4-4ce7-85cd-22e617983f48",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_shirt.webp?alt=media&token=15b54702-b939-4e21-a907-4a9ae336511b",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_multipack.webp?alt=media&token=bbfa01f9-c9c1-4c6d-9a02-ba8343c253a6",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_text.webp?alt=media&token=fc2fe571-8865-4551-b794-32b748a4e83a",
        width: "half",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fsanzo%2Fsanzo_all-cans.webp?alt=media&token=d1955db9-39ed-4f3b-8f55-2f68e4addcf0",
        width: "full",
      },
    ],
    credits: [
      { name: "Jolene Delisle", role: "Creative Director" },
      {
        name: "Christine Brown",
        role: "Senior Designer",
        link: "http://christine-brown.com/",
      },
      {
        name: "Garnish Studios",
        role: "Documentation",
        link: "https://www.garnishstudios.com/",
      },
    ],
  },
  "phonetic-typography": {
    client: "Thesis Project",
    title: "Phonetic Typography",
    headline: "Exploring the intersection of speech and typography",
    intro:
      "Phonetic Typography explores the relationship between speech and type through the generation of typographic forms that capture fluctuations in speech, allowing users to see how a sentence is said just by looking at it.",
    tags: ["processing", "app design", "type design"],
    type: ["code", "design"],
    cover:
      "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fphonetic-typography%2Fphonetic_cover.gif?alt=media&token=076dce9d-3b6b-460c-b546-f850a46ffe2b",
    link: [
      {
        link: "https://markthesis.github.io/phonetic-typography/",
        label: "Try it out! [Requires Google Chrome]",
      },
    ],
    content: [
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fphonetic-typography%2Fphonetic_user.jpg?alt=media&token=a29087d3-4eac-4a3b-b29f-3a66cf239560",
        width: "full",
      },
      {
        type: "image",
        content:
          "https://firebasestorage.googleapis.com/v0/b/markcwy-portfolio.appspot.com/o/projects%2Fphonetic-typography%2Fphonetic_postcards.jpg?alt=media&token=09816cb6-43d9-4721-915d-35ae2690aa73",
        width: "full",
      },
    ],
  },
};

const projectLoop = () => {
  let output: ProjectTileType[] = [];
  Object.keys(projectData).forEach((key) =>
    output.push({
      key,
      client: projectData[key].client,
      title: projectData[key].title,
      tags: projectData[key].tags,
      type: projectData[key].type,
      cover: projectData[key].cover,
    })
  );
  return output;
};

const projectDataSummary: ProjectTileType[] = projectLoop();

export { projectDataSummary, projectData };
