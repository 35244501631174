import React from "react";
import "./ProjectPageCard.css";
import { ProjectPageCardType, ProjectType } from "../../Types/CustomTypes";
import Fade from "../../Motion/Fade";

interface Props {
  content: ProjectPageCardType;
  type: ProjectType;
  index: number;
}

const ProjectPageCard: React.FC<Props> = ({ content, type, index }) => {
  return (
    <Fade
      className={`project_card project_card-${content.width} project_card-${content.type} project_card-${type}`}
      stagger={index}
    >
      {content.type === "text" ? (
        <div className="p-large">{content.content}</div>
      ) : (
        <img
          className="project_card__image"
          src={content.content}
          alt={content.type}
        />
      )}
    </Fade>
  );
};

export default ProjectPageCard;
