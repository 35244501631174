import { TagType } from "../Types/CustomTypes";

const designTags: TagType[] = [
  "packaging",
  "web design",
  "app design",
  "brand identity",
  "editorial",
  "type design",
  "illustration",
  "copywriting",
];

const codeTags: TagType[] = [
  "processing",
  "frontend",
  "backend",
  "react",
  "firebase",
  "sequelize",
  "express.js",
];

const photoTags: TagType[] = ["documentation"];

const allTags: TagType[] = [...designTags, ...codeTags, ...photoTags];

export { designTags, codeTags, photoTags, allTags };
