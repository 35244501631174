import React from "react";
import "./HomePage.css";
import { projectDataSummary } from "../../Data/ProjectData";
import ProjectGallery from "../../Parts/ProjectGallery/ProjectGallery";
import Fade from "../../Motion/Fade";

const HomePage: React.FC = () => {
  return (
    <Fade className="content home">
      <div className="home__header">
        <h1>
          Mark is a Singapore-based Developer, Graphic Designer & Photographer
        </h1>
      </div>
      <div className="home__projects">
        <h4>Latest Projects</h4>
        <ProjectGallery projects={projectDataSummary} limit={9} />
      </div>
    </Fade>
  );
};

export default HomePage;
