import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Nav from "../Parts/Nav/Nav";
import Footer from "../Parts/Footer/Footer";
import { AnimatePresence } from "framer-motion";

const MainOutlet: React.FC = () => {
  const { pathname } = useLocation();
  const element = document.getElementById("page-top");

  useEffect(() => {
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, [pathname, element]);

  return (
    <div className="App">
      <Nav />
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default MainOutlet;
