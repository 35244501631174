import React, { useEffect, useState } from "react";
import "./ProjectGallery.css";
import { ProjectTileType } from "../../Types/CustomTypes";
import ProjectTile from "../../Pieces/ProjectTile/ProjectTile";

interface Props {
  projects: ProjectTileType[];
  limit?: number;
}

const ProjectGallery: React.FC<Props> = ({ projects, limit }) => {
  const [tiles, setTiles] = useState<ProjectTileType[]>([]);

  useEffect(() => {
    if (limit) {
      setTiles(projects.slice(0, limit));
    } else {
      setTiles(projects);
    }
  }, [limit, projects]);

  return (
    <div className="project_gallery">
      {tiles.map((project, index) => (
        <ProjectTile project={project} key={project.key} index={index} />
      ))}
    </div>
  );
};

export default ProjectGallery;
