import React from "react";
import "./ProjectTile.css";
import Tag from "../Tag/Tag";
import { useNavigate } from "react-router-dom";
import { ProjectTileType } from "../../Types/CustomTypes";
import Fade from "../../Motion/Fade";

interface Props {
  project: ProjectTileType;
  index: number;
}

const ProjectTile: React.FC<Props> = ({ project, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${project.type[0]}/${project.key}`);
  };

  return (
    <Fade className="project_tile" stagger={index}>
      <div
        className={`project_tile__image project_tile__image-${project.type[0]} clickable`}
        onClick={handleClick}
      >
        {project.cover ? (
          <img src={project.cover} alt={project.title} />
        ) : (
          <></>
        )}
      </div>
      <div className="project_tile__details">
        <h4>{project.client}</h4>
        <h3 onClick={handleClick} className="clickable">
          {project.title}
        </h3>
        <div className="project_tile__tags">
          {project.tags.map((tag, index) => (
            <Tag name={tag} key={index} />
          ))}
        </div>
      </div>
    </Fade>
  );
};

export default ProjectTile;
