import React, { useEffect, useState } from "react";
import "./Tag.css";
import { ProjectType, TagSize, TagType } from "../../Types/CustomTypes";
import { useNavigate } from "react-router-dom";
import { codeTags, designTags, photoTags } from "../../Data/Tags";
import { titleCase } from "../../Utilities/utils";

interface Props {
  name: string;
  size?: TagSize;
}

const Tag = ({ name, size = "small" }: Props) => {
  const [type, setType] = useState<ProjectType>(null);
  const formattedName: TagType = name.toLowerCase() as TagType;
  const navigate = useNavigate();

  useEffect(() => {
    if (designTags.includes(formattedName)) {
      setType("design");
    } else if (codeTags.includes(formattedName)) {
      setType("code");
    } else if (photoTags.includes(formattedName)) {
      setType("photo");
    }
  }, [formattedName]);

  const handleClick = (e: React.SyntheticEvent) => {
    navigate(`/search?tag=${formattedName}`);
  };

  return (
    <div
      className={`tag-${size} ${type && `tag-${type}`} clickable`}
      onClick={handleClick}
    >
      {size === "small" ? <h5>{name}</h5> : <h6>{titleCase(name)}</h6>}
    </div>
  );
};

export default Tag;
