import { motion } from "framer-motion";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  stagger?: number;
}

const Fade: React.FC<Props> = ({ children, className, stagger }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: "10px" }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
        delay: stagger ? (stagger % 3) * 0.1 : 0,
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
};
export default Fade;
