import React from "react";
import Tag from "../../Pieces/Tag/Tag";
import "./ProjectPage.css";
import ProjectPageCard from "../../Pieces/ProjectPageCard/ProjectPageCard";
import { useParams } from "react-router-dom";
import { projectData } from "../../Data/ProjectData";
import Fade from "../../Motion/Fade";

const ProjectPage: React.FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  if (projectId) {
    const project = projectData[projectId];
    return (
      <Fade className="content">
        <div className="project__header">
          <h4>{project.client}</h4>
          <h2>{project.headline}</h2>
          <div className="project__tags">
            {project.tags.map((tag, index) => (
              <Tag name={tag} key={index} />
            ))}
          </div>
          {project.intro && (
            <div className="project__intro">
              <p>{project.intro}</p>
            </div>
          )}
          {project.link && (
            <div className="project__links">
              {project.link.map((link, index) => (
                <a
                  className="project__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link.link}
                  key={index}
                >
                  <h4>{link.label}</h4>
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="content-offset project__content">
          {project.content.map((content, index) => (
            <ProjectPageCard
              content={content}
              type={project.type[0]}
              index={index}
              key={index}
            />
          ))}
        </div>
        {project.credits && (
          <div className="project__credits">
            <h4>Credits</h4>
            {project.credits.map((content, index) =>
              content.link ? (
                <div className="project__credits_single">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={content.link}
                    key={index}
                  >
                    <h6>{content.name}</h6>
                    <h4>{content.role}</h4>
                  </a>
                </div>
              ) : (
                <div className="project__credits_single" key={index}>
                  <h6>{content.name}</h6>
                  <h4>{content.role}</h4>
                </div>
              )
            )}
          </div>
        )}
      </Fade>
    );
  } else {
    return <h1>Loading</h1>;
  }
};

export default ProjectPage;
