const capitalise = (string: string) => {
  const letters: string[] = string.split("");
  letters[0] = letters[0].toUpperCase();
  return letters.join("");
};

const titleCase = (string: string) => {
  const words: string[] = string.split(" ");
  const output: string[] = [];
  words.forEach((word) => {
    const text = capitalise(word);
    output.push(text);
  });
  return output.join(" ");
};

export { capitalise, titleCase };
