import React from "react";
import { useLocation } from "react-router-dom";
import ProjectGallery from "../../Parts/ProjectGallery/ProjectGallery";
import { projectDataSummary } from "../../Data/ProjectData";
import "./PortfolioPage.css";
import { ProjectType } from "../../Types/CustomTypes";
import Fade from "../../Motion/Fade";

const PortfolioPage: React.FC = () => {
  const location = useLocation();
  const page: string = location.pathname.slice(1);
  return (
    <Fade className="content portfolio">
      <div className="portfolio__header">
        <h1>{page}</h1>
      </div>
      <ProjectGallery
        projects={projectDataSummary.filter((project) =>
          project.type.includes(page as ProjectType)
        )}
      />
    </Fade>
  );
};

export default PortfolioPage;
